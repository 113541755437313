<template>
  <div>
    <div class="row justify-content-center margin-auto">
      <div class="width-25 w-res-90 data-progres">
        <span
          v-bind:class="{'is-active':dataAnggota === true}"
          @click="dataAnggota = true "
          class="shadow-md cursor-pointer m-2 border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5"
        >Keluarga</span>
      </div>
      <div class="width-25 w-res-90 data-progres">
        <span
          v-bind:class="{'is-active':dataAnggota === false}"
          @click="dataAnggota = false "
          class="shadow-md cursor-pointer m-2 border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5"
        >Anggota Keluarga</span>
      </div>
    </div>
    <div v-show="dataAnggota === true">
      <highcharts ref="barChart" :options="chartOptionsKeluarga"></highcharts>
    </div>
    <div v-show="dataAnggota === false">
      <highcharts ref="barChart" :options="chartOptionsAnggota"></highcharts>
    </div>
  </div>
</template>
<script>
export default {
  name: "chart-adminduk",
   props: {
    Triger: null
  },
  data() {
    return {
      dataAnggota: true,
      chartOptionsKeluarga: {
        chart: {
          type: "bar"
        },
        title: {
          text: ""
        },
        xAxis: {
          categories: [
            "Total Keluarga",
            "Total Keluarga Memiliki akta/Buku Nikah",
            "Total Keluarga Memiliki KK"
          ]
        },
        tooltip: {
          formatter: function() {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal +
              " Jiwa"
            );
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          }
        },
        legend: {
          enabled: true,
          reversed: true
        },
        plotOptions: {
          series: {
            stacking: "normal",
            dataLabels: {
                enabled: true
            }
          }
        },
        series: [
          {
            name: "Non-OAP",
            data: [36, 120, 20],
            color: "#808080"
          },
          {
            name: "OAP",
            data: [24, 10, 20],
            color: "#07980C"
          }
        ]
      },
      chartOptionsAnggota: {
        chart: {
          type: "bar"
        },
        title: {
          text: ""
        },
        xAxis: {
          categories: [
            "Total Jiwa",
            "Jiwa > 17 Tahun Memiliki KTP",
            "Jiwa Memiliki Akte kelahiran"
          ]
        },
        tooltip: {
          formatter: function() {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal +
              " Jiwa"
            );
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          }
        },
        legend: {
          enabled: true,
          reversed: true
        },
        plotOptions: {
          series: {
            stacking: "normal",
            dataLabels: {
                enabled: true
            }
          }
        },
        series: [
          {
            name: "Non-OAP",
            data: [36, 120, 20],
            color: "#808080"
          },
          {
            name: "OAP",
            data: [24, 10, 20],
            color: "#07980C"
          }
        ]
      }
    };
  },
  mounted() {
    this.getChartDataKeluarga()
    this.getChartDataAnggota()
  },
  methods: {
    getChartDataKeluarga() {
      this.$axios.get('/v1/sensus/city/adminduk-keluarga/'+ this.Triger)
        .then(response => {
          let result = response.data 
          this.chartOptionsKeluarga = {
            chart: {
              type: "bar"
            },
            title: {
              text: ""
            },
            xAxis: {
              categories: result.xAxis.categories
            },
            tooltip: {
              formatter: function() {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "<br/>" +
                  "Total: " +
                  this.point.stackTotal +
                  " Jiwa"
                );
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: ""
              }
            },
            legend: {
              enabled: true,
              reversed: true
            },
            plotOptions: {
              series: {
                stacking: "normal",
                dataLabels: {
                  enabled: true
                }
              }
            },
            series: result.series
          }
        })
    },
    getChartDataAnggota() {
      this.$axios.get('/v1/sensus/city/adminduk-anggota/'+ this.Triger)
        .then(response => {
          let result = response.data
          this.chartOptionsAnggota = {
            chart: {
              type: "bar"
            },
            title: {
              text: ""
            },
            xAxis: {
              categories: result.xAxis.categories
            },
            tooltip: {
              formatter: function() {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "<br/>" +
                  "Total: " +
                  this.point.stackTotal +
                  " Jiwa"
                );
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: ""
              }
            },
            legend: {
              enabled: true,
              reversed: true
            },
            plotOptions: {
              series: {
                stacking: "normal",
                dataLabels: {
                  enabled: true
                }
              }
            },
            series: result.series
          }
        })
    }
  }
};
</script>