<template>
  <div>
    <div class="row justify-content-center margin-auto">
      <div class="width-25 w-res-90 data-progres">
        <span
          v-bind:class="{ 'is-active': dataAnggota === true }"
          @click="dataAnggota = true"
          class="shadow-md cursor-pointer m-2 border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5"
          >Individu</span
        >
      </div>
      <div class="width-25 w-res-90  data-progres">
        <span
          v-bind:class="{ 'is-active': dataAnggota === false }"
          @click="dataAnggota = false"
          class="shadow-md cursor-pointer m-2 border-solid-1 bc-primary bg-white font-14 text-center text-primary d-block p-2 font-weight-6 border-radius-5"
          >Keluarga</span
        >
      </div>
    </div>
    <div v-show="dataAnggota === true">
      <highcharts ref="barChart" :options="chartOptionsIndividu"></highcharts>
    </div>
    <div v-show="dataAnggota === false">
      <highcharts ref="barChart" :options="chartOptionsKeluarga"></highcharts>
    </div>
  </div>
</template>
<script>
export default {
  name: "chart-bantuan-sosial",
  props: {
    Triger: null,
  },
  data() {
    return {
      dataAnggota: true,
      chartOptionsIndividu: {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "Total Jiwa",
            "Partisipan Bantuan Sosial",
            "Jiwa Memiliki Akte Kelahiran",
            "KIP",
            "BSM",
            "KIS",
            "JAMKESMAS",
            "BPJS Mandiri",
            "BPJS Ketenagakerjaan",
            "Asuransi Kesehatan Lain",
          ],
        },
        tooltip: {
          formatter: function() {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal
            );
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        legend: {
          enabled: true,
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: [
          {
            name: "Non-OAP",
            data: [120, 120, 20, 120, 120, 20, 120, 120, 20, 20],
            color: "#808080",
          },
          {
            name: "OAP",
            data: [60, 90, 10, 80, 80, 10, 80, 90, 10, 10],
            color: "#07980C",
          },
        ],
      },
      chartOptionsKeluarga: {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "Total Keluarga",
            "Total Keluarga Ikut BANSOS ",
            "KKS",
            "PKH",
            "RASKIN",
            "KUR",
          ],
        },
        tooltip: {
          formatter: function() {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal
            );
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        legend: {
          enabled: true,
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        series: [
          {
            name: "Non-OAP",
            data: [36, 120, 20, 36, 120, 20],
            color: "#808080",
          },
          {
            name: "OAP",
            data: [18, 60, 10, 18, 60, 15],
            color: "#07980C",
          },
        ],
      },
    };
  },
  mounted() {
    this.getDataChartIndividu();
    this.getDataChartKeluarga();
  },
  methods: {
    getDataChartIndividu() {
      this.$axios
        .get("/v1/sensus/city/bantuan-sosial-individu/" + this.Triger)
        .then((response) => {
          let result = response.data;
          this.chartOptionsIndividu = {
            chart: {
              type: "bar",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: result.xAxis.categories,
            },
            tooltip: {
              formatter: function() {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "<br/>" +
                  "Total: " +
                  this.point.stackTotal
                );
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "",
              },
              stackLabels: {
                enabled: true,
                style: {
                  fontWeight: "bold",
                  color: "gray",
                },
              },
            },
            legend: {
              enabled: true,
              reversed: true,
            },
            plotOptions: {
              series: {
                stacking: "normal",
              },
            },
            series: result.series,
          };
        });
    },
    getDataChartKeluarga() {
      this.$axios
        .get("/v1/sensus/city/bantuan-sosial-keluarga/" + this.Triger)
        .then((response) => {
          let result = response.data;
          this.chartOptionsKeluarga = {
            chart: {
              type: "bar",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: result.xAxis.categories,
            },
            tooltip: {
              formatter: function() {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "<br/>" +
                  "Total: " +
                  this.point.stackTotal
                );
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "",
              },
              stackLabels: {
                enabled: true,
                style: {
                  fontWeight: "bold",
                  color: "gray",
                },
              },
            },
            legend: {
              enabled: true,
              reversed: true,
            },
            plotOptions: {
              series: {
                stacking: "normal",
              },
            },
            series: result.series,
          };
        });
    },
  },
};
</script>
